<template>
  <div class="home-page">
    <h1 style="margin-bottom: 80px;">选择单位</h1>
    <button
      v-for="unit in units"
      :key="unit.F_ID"
      class="nav-btn"
      @click="goToExam(unit.F_ID)"
      :disabled="unit.F_ID > 1"
    >
      {{ unit.F_Name }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      units: [],
    };
  },
  methods: {
    goToExam(department) {
      const user = this.$route.query.user;
      if (user) {
        const decodedUser = decodeURIComponent(user);
        localStorage.setItem("user", decodedUser);
      }
      this.$router.push(`/rule/${department}`);
    },
    fetchUnits() {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/units`, {
        credentials: "include", // 确保请求携带凭证
      })
        .then((response) => response.json())
        .then((data) => {
          this.units = data;
          //console.log("获取单位信息成功:", data);
        })
        .catch((error) => {
          console.error("获取单位信息失败:", error);
        });
    },
  },
  mounted() {
    const user = this.$route.query.user;
    if (user) {
      const decodedUser = decodeURIComponent(user);
      localStorage.setItem("user", decodedUser);
    }
    this.fetchUnits();
    document.title = "选择单位"; // 设置页面标题
  },
};
</script>

<style scoped>
.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
h1 {
  font-size: 32px;
}

.nav-btn {
  margin: 15px;
  padding: 10px 20px;
  font-size: 22px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 250px;
}

.nav-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>
