<template>
  <div class="exam-rule-page">
    <button class="back-btn" @click="goBack">返回</button>
    <h1 class="title">考试规则</h1>
    <p class="rule-text">
      请认真阅读以下考试规则，确保在考试过程中遵守所有规定。考试时间为60分钟，请合理安排答题时间。<br>
      一共78道题，44道单选每题1分，10道多选每题2分，20道判断每题1分，4道案例题每题4分。<br>合计100分，70分及格 ，
    </p>
    <div class="button-container">
      <button class="btn" @click="startExam">开始考试</button>
      <button class="btn" @click="viewRecords">考试记录</button>
    </div>
  </div>
</template>
<style scoped>
.swal2-popup {
  font-size: 10px; /* 调整字体大小 */
}
</style>
<script>
/* global WeixinJSBridge */
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      department: null,
      userId: null,
      isPaid: false,
    };
  },
  methods: {
    async confirmPayment() {
      const result = await Swal.fire({
        title: '中科创维',
        text: '您还未付款，是否进行付款？',
        icon: null,
        showCancelButton: true,
        confirmButtonText: '是',
        cancelButtonText: '否',
      });
      if (result.isConfirmed) {
        this.initiateWeChatPayment();
      }
    },
    startExam() {
      if (this.isPaid) {
        this.$router.push(`/exam/${this.department}`);
      } else {
        this.confirmPayment();
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    viewRecords() {
      this.$router.push("/records");
    },
    async checkPaymentStatus() {
      console.log("检查付款状态开始"); // 添加日志
      try {
        const response = await this.$axios.get("/api/isPay", {
          params: {
            userId: this.userId,
            department: this.department,
          },
        });
        this.isPaid = response.data.isPaid;
        console.log("付款状态:", this.isPaid);
      } catch (error) {
        //console.error("检查付款状态失败:", error);
      }
    },
    initiateWeChatPayment() {
      // 调用微信支付的逻辑
      // 这里可以调用后端接口生成支付订单，然后跳转到微信支付页面
      this.$axios
        .post("/api/wechatPay", {
          userId: this.userId,
          department: this.department,
        })
        .then((response) => {
          const { appId, prepay_id, timeStamp, nonceStr, paySign } =
            response.data;
          this.callWeChatPay(appId, prepay_id, timeStamp, nonceStr, paySign);
        })
        .catch((error) => {
          console.error("微信支付初始化失败:", error);
        });
    },
    callWeChatPay(appId, prepay_id, timeStamp, nonceStr, paySign) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            () =>
              this.onBridgeReady(
                appId,
                prepay_id,
                timeStamp,
                nonceStr,
                paySign
              ),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", () =>
            this.onBridgeReady(appId, prepay_id, timeStamp, nonceStr, paySign)
          );
          document.attachEvent("onWeixinJSBridgeReady", () =>
            this.onBridgeReady(appId, prepay_id, timeStamp, nonceStr, paySign)
          );
        }
      } else {
        this.onBridgeReady(appId, prepay_id, timeStamp, nonceStr, paySign);
      }
    },
    onBridgeReady(appId, prepay_id, timeStamp, nonceStr, paySign) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: appId, // 公众号名称，由商户传入
          timeStamp: timeStamp, // 时间戳，自1970年以来的秒数
          nonceStr: nonceStr, // 随机串
          package: "prepay_id=" + prepay_id,
          signType: "HMAC-SHA256", // 微信签名方式：
          paySign: paySign, // 微信签名
        },
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 支付成功后的操作
            Swal.fire('支付成功');
            this.$router.push(`/exam/${this.department}`); // 跳转到考试页面
          } else {
            // 支付失败后的操作
            Swal.fire('支付失败');
          }
        }
      );
    },
  },
  mounted() {
    this.department = this.$route.params.department;
    const user = localStorage.getItem("user");
    if (user) {
      const userInfo = JSON.parse(decodeURIComponent(user));
      this.userId = userInfo.userId;
    }
    //console.log("用户信息:", this.userId, this.department); // 添加日志
    this.checkPaymentStatus(); // 页面加载时检查付款状态
    document.title = "考试规则"; // 设置页面标题
  },
};
</script>
<style scoped src="@/assets/examrule.css"></style>
