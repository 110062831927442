import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import log from '@/utils/logger'; // 引入日志模块

const app = createApp(App);
// 将 axios 注入到 Vue 实例中
app.config.globalProperties.$axios = axios;

app.use(router);

app.mixin({
  created() {
    // 检查 URL 中是否有用户信息
    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get('user');
    if (user) {
      log.info(`[mixin] Found user information in URL: ${user}`);
      // 将用户信息存储在本地存储中
      localStorage.setItem('user', user);
      // 移除 URL 中的用户信息
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    else{
      log.info('[mixin] No user information found in URL');
    }
  }
});

app.mount('#app');